.Modal {
  overflow: auto;
}

.trigger-dag-page-controls-left {
  display: flex;
  justify-content: space-between;

  .left-things {
    display: flex;
    flex-direction: column;
  }

  .right-things {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .csv-label {
      color: unset;
      background: unset;
    }
  }
}

.trigger-dag-page-controls-right {
  display: flex;
  justify-content: right;

  .right-things {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .csv-label {
      color: unset;
      background: unset;
    }
  }
}

.trigger-dag-page-controls {
  .right-things {
    .csv-label {
      color: unset;
      background: unset !important;
    }
  }
}

.right-things {
  .csv-label {
    color: unset;
    background: unset !important;
  }
}


.promo-trigger-dag-text-group {
  width: 860px;
}


#extracts-trigger-confirmation-modal {
  #has-retailer-ids {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .table {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      line-height: 26px;
      margin-bottom: 10px;

      .left {
        font-weight: bold;
        flex-basis: 25%;
        text-align: right;
      }

      .right {
        text-align: left;
        flex-basis: 75%;
        margin-left: 8px;
      }
    }
  }
}

.horiz-container {
  display: flex;
  margin-bottom: 20px;
}