.toggle-radio-button {
  visibility: hidden;
  margin-bottom: unset;
  display: none;
}

.toggle-radio-button-selected {
  background: #E9E9E9;
}

label.toggle-radio-button-label {
  border: 1px solid #D9D9D9;
  padding: 6px 12px;
  margin: 0;
}

.toggle-radio-button-container {
  display: flex;
  flex-direction: row;
  label:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  label:first-of-type {
    border-radius: 4px 0 0 4px;
  }
}
