.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    margin-right: 8px;
  }

  .spinner-carrot {
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
}

.spinner-container-normal {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;

  .spinner {
    margin-right: 8px;
  }

  .spinner-carrot {
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
}

.spinner-container-normal {
  font-size: 32px;
  height: 600px;
}

.spinner-container-small {
  font-size: 12px;
  height: 24px;
}