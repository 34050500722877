.ReactTable7 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.ReactTable7 * {
  box-sizing: border-box;
}

.react-table-wrapper {
  .sticky {
    .headerForStickyHeader {
      position: relative;
      top: 0;
      z-index: 1;
    }

    .bodyForStickyHeader {
      z-index: 0;
    }
  }
}

.ReactTable7 .rt-table {
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  width: 100%;
  overflow: auto;
}

.ReactTable7 .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #F4F6F8;
  font-weight: bold;
  box-shadow: inset -1px 0px 0px #D9D9D9;

  input {
    background: #FFFFFF !important;
    border: 1px solid #B1B1B1 !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    height: 32px;
    width: 100%
  }
}

.ReactTable7 .add-button-icon {
  color: #32be3f;
}

.react-table-edit-modal {
  position: absolute;
  min-width: 25%;
  max-width: 75%;
  max-height: 88%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  .edit-modal-data-row {

    .field-edit-section {
      width: 400px;
    }

    .edit-form {
      display: flex;
      flex-wrap: wrap;

      .edit-form-row {
        width: 100%;
      }

      .right-things {
        width: 100%;
      }
    }

  }
}

.ReactTable7 .minus-button-icon {
  color: #e74c3c;
  padding-left: 4px;
}

.ReactTable7 .rt-thead.-headerGroups {
  background: #F4F6F8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable7 .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable7 .rt-thead.-filters input,
.ReactTable7 .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #F4F6F8;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable7 .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable7 .rt-thead .rt-tr {
  text-align: left;
}

.ReactTable7 .rt-thead .rt-td {
  padding: 12px;
}

.ReactTable7 .rt-thead .rt-th {
  padding: 8px 12px;
}

.ReactTable7 .rt-thead .rt-th,
.ReactTable7 .rt-thead .rt-td {
  line-height: normal;
  position: relative;
  border: 1px solid #E2E2E2;
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  background: #f4f6f8;
}

.ReactTable7 .rt-tbody .rt-td {
  &.no-padding {
    padding: 0;
  }
}


.ReactTable7 .rt-filterable-content {
  margin-top: 12px;
}

.ReactTable7 .rt-filterable-content select {
  height: 32px;
  padding-left: 0.5em;
  margin: 0;
}

.ReactTable7 .rt-thead .rt-th.-sort-asc,
.ReactTable7 .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable7 .rt-thead .rt-th.-sort-desc,
.ReactTable7 .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable7 .rt-thead .rt-th.-cursor-pointer,
.ReactTable7 .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable7 div[role="row"]:nth-child(even) {
  background-color: #FAFAFA;
}

.ReactTable7 .rt-thead .rt-th:last-child,
.ReactTable7 .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable7 .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable7 .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable7 .rt-thead .rt-resizable-header-content {
  color: var(--neutral-90, #474747);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body Heavy */
  //font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */

  border: 0px solid var(--neutral-30, #D9D9D9);
  background: var(--blue-washed, #F4F6F8);
}

.ReactTable7 .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable7 .rt-thead .rt-header-pivot:after,
.ReactTable7 .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable7 .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}

.ReactTable7 .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}

.ReactTable7 .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}

.ReactTable7 .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable7 .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable7 .rt-tbody .rt-td {
  border-right: 1px solid #E2E2E2;
  border-bottom: 1px solid #E2E2E2;
  padding: 8px 12px;
  color: var(--neutral-100, #252525);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Body */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

}

.ReactTable7 .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable7 .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable7 .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ReactTable7 .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

body .ReactTable7 .non-numeric-field {
  text-align: start;
  padding: 4px 8px;
}

body .ReactTable7 .line-wrap-field {
  white-space: break-spaces;
}

body .ReactTable7 .numeric-field {
  text-align: end;
  padding: 4px 8px;
}

.ReactTable7 .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable7 .rt-th {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable7 .rt-th.-hidden,
.ReactTable7 .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable7 .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable7 .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable7 .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable7 .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable7 .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable7 .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable7 .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable7 .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}

.ReactTable7.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}

.ReactTable7 .-pagination {
  z-index: 0;
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
}

.ReactTable7 .-pagination .-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 8px;
}

.ReactTable7 .-pagination input,
.ReactTable7 .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
  width: 150px;
}

.ReactTable7 .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
  margin: 4px;
  background-color: #1779ba;
  color: #fefefe;
  font-weight: bold;
}

.ReactTable7 .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.ReactTable7 .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.ReactTable7 .-pagination .-previous,
.ReactTable7 .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.ReactTable7 .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.ReactTable7 .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}

.ReactTable7 .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable7 .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable7 .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable7 .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.ReactTable7 .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable7 .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable7 .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable7 .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.ReactTable7 .rt-resizing .rt-th,
.ReactTable7 .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ReactTable7 .rt-table {
  overflow-y: visible !important;
  //noinspection CssInvalidPropertyValue
  overflow-x: auto !important;
  flex-wrap: wrap;
}

.ReactTable7 .rt-tbody {
  overflow-y: visible !important;
  //noinspection CssInvalidPropertyValue
  overflow-x: visible !important;
}

.task-chevron-up {
  width: 12px;

  height: 12px;
  margin: 0 0 0 4px;
  transform: rotate(180deg)
}

.inactive-retailer-row {
  background-color: #dbd3d3;
}

.inactive-store-row {
  background-color: #E2E2E2;

  div {
    background-color: #E2E2E2 !important;
  }
}

.left-gutter-alert div[role="cell"]:is(:first-child) {
  border-left: 4px solid get-color(alert);
}

.left-gutter-warning div[role="cell"]:is(:first-child) {
  border-left: 4px solid get-color(warning);
}

.left-gutter-success div[role="cell"]:is(:first-child) {
  border-left: 4px solid get-color(success);
}

.left-dotted-border div[role="cell"]:is(:first-child) {
  border-left: 4px dotted get-color(alert);
}

.data-row-dropdown {
  font-size: 14px;
  font-weight: normal;
  border-color: #b1b1b1;

  .data-row-cell-select__control {
    height: 34px;
    min-height: 34px;
    margin-bottom: 1rem;
  }

  .data-row-cell-select__value-container {
    height: 34px;
  }

}

.alert-type-filer-dropdown-container {
  width: 100%;
  font-size: 14px;
  font-weight: normal;

  .alert-type-filer-dropdown__input {
    input {
      box-sizing: border-box !important;
      border-radius: 3px !important;
      height: 24px !important;
      padding: 0 !important;
      background: 0 center !important;
      border: 0 !important;
    }
  }

  div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
    min-height: 32px !important;
    max-height: 32px;
  }

  div[class$="-Input"] {
    min-height: 32px !important;
    max-height: 32px;
    padding: 0;
  }

  div[class$="-control"] {
    min-height: 32px !important;
    max-height: 32px;
  }

  div[class$="-ValueContainer"] {
    min-height: 32px !important;
    max-height: 32px;
  }
}

.ReactTable7 .button {
  margin-bottom: unset;
}

.sticky-column {
  background: #f4f6f8;
}

.-pagination {
  .react-table-pagination-button {
    background: #fefefe !important;
  }

  .react-table-pagination-icon {
    color: #252525;
  }

  .react-table-pagination-icon-last {
    color: #252525;
    border-right: 2px solid #252525;
    padding-right: 2px;
  }

  .react-table-pagination-icon-first {
    color: #252525;
    border-left: 2px solid #252525;
    padding-left: 2px;
  }
}

.table-cell-selected input,
.ReactTable7 .rt-thead .table-cell-selected input {
  width: 20px;
  height: 20px;
}

.rt-thead .table-cell-selected input {
  position: absolute;
  bottom: 0;
}

.ReactTable7 .rt-td-wrap-word {
  overflow-wrap: break-word;
  white-space: initial;
}

.ReactTable7 .display-linebreak {
  white-space: pre-line;
}


.time-service-edit-modal {
  overflow: scroll;
  min-width: unset;
  max-width: unset;
  width: 75%;

  .edit-modal-data-row {

    .edit-form {
      .edit-form-row {
        width: 50% !important;
        padding: 0 8px;
      }

      .edit-row-index {
        width: 100% !important;
      }

    }

  }


}

.ReactTable7 {
  .rt-rt-footer-group {
    background: rgb(244, 246, 248);
    z-index: 0;

    .footer-rt-tr {
      font-weight: bold;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      padding: 8px 16px 8px 0;
      text-align: right;
      .table-footer-cell-storeName, .table-footer-cell-storeId, .total-dollar-average, .total-unit-average {
        text-align: left;
        background: rgb(244, 246, 248) !important;
        padding-left: 8px;
      }
    }
  }
}

.ReactTable7 {

  .timeService-invalid-week {
    font-weight: bold !important;
    color: red !important;
  }

  .time-service-active-icon {
    margin-right: 8px;
    margin-bottom: 2px;
  }

  .timeService-last-period {
    background: #E2E2E2 !important;

    .table-cell-actions {
      background: #E2E2E2 !important;
    }
  }

  .table-cell-timeServiceBaseInformationSection {
    background: #E2E2E2 !important;

    .rt-resizable-header-content {
      background: #E2E2E2 !important;
      color: #FFFFFF;
    }
  }

  .table-cell-timeServiceCurrentPeriodSection {
    background: #6FBE46 !important;

    .rt-resizable-header-content {
      background: #6FBE46 !important;
      color: #FFFFFF;
    }
  }

  .table-cell-timeServiceReleaseSection {
    background: #449711 !important;

    .rt-resizable-header-content {
      background: #449711 !important;
      color: #FFFFFF;
    }
  }
  .table-cell-timeServiceRestatementPeriodSection {
    background: #316e0c !important;

    .rt-resizable-header-content {
      background: #316e0c !important;
      color: #FFFFFF;
    }
  }

  .table-cell-geographicalAddress {
    span {
      color: #e32836;
      font-size: 10px;
      margin-left: 4px;
    }
  }
}

.skip-stores-service-edit-modal {
  overflow: scroll;
  min-width: unset;
  max-width: unset;
  width: 75%;

  .edit-modal-data-row {

    .edit-form {
      .edit-form-row {
        width: 50% !important;
        padding: 0 8px;
      }

      .edit-row-storeId, .edit-row-index {
        width: 100% !important;
      }

    }

  }


}

.store-service-edit-modal {
  overflow: scroll;
  min-width: unset;
  max-width: unset;
  width: 75%;

  .edit-modal-data-row {

    .edit-form {
      .edit-form-row {
        width: 50% !important;
        padding: 0 8px;

        .form-dropdown-component-container {
          font-size: 14px;
        }

        .edit-form-text-input-field {
          height: 46px !important;
        }

        .edit-form-number-input-field {
          height: 46px !important;
        }
      }

      .edit-row-account{
        width: 100% !important;
      }

      .edit-row-storeAddress {
        width: 100% !important;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        .edit-form-row-address-map {
          width: 50% !important;
          margin-top: 16px;

          .hidden-map-for-google-places-api {
            display: none;
          }

          #mapBox {
            width: 100% !important;
            height: 400px;
            padding: 6px 12px;
          }
        }

        .address-location-out-of-sync {
          width: 100% !important;
        }

        .edit-form-row-address-controls {
          width: 50% !important;
          margin-top: 16px;
          //display: block;

          p {
            color: #e32836;
            font-weight: bold;
          }

          .geographical-address-block{
            padding: 16px
          }
          .checkbox-container {
            display: flex;
            align-items: center;
            font-size: 12px;
            text-align: left;
            font-family: sans-serif;

            .Dropdown-control {
              margin-left: 8px;
              margin-right: 16px;
            }

            .checkbox-filters-label {
              left: 20px;
              top: 22px;
              font-size: 14px;
              line-height: 28px;
            }

            .checkbox-label {
              background-color: #E9E9E9;
              border: 1px solid #ccc;
              border-radius: 20px;
              cursor: pointer;
              height: 32px;
              text-align-last: center;
              padding-left: 16px;
              padding-right: 16px;
              padding-top: 4px;
              margin: 0 0.5rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .checkbox-label:before {
              content: '✓';
              line-height: 1em;
              width: 1em;
              height: 1em;
              text-align: center;
              opacity: 0;
              display: none;
            }

            input[type="checkbox"] {
              visibility: hidden;
              margin-bottom: unset;
              margin-left: 10px;
              display: none;
            }

            input[type="checkbox"]:checked + .checkbox-label {
              background-color: #666666;
              border-color: #666666;
              color: #FFFFFF;
              font-weight: bold;
            }

            input[type="checkbox"]:checked + .checkbox-label:before {
              opacity: 1;
              display: inline-block;
            }
          }
        }
      }
    }

  }
}
