.search {
  padding-top: 16px;
  padding-right: 16px;
  position: relative;
}

.results {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  background: whitesmoke;
}