.react-contextmenu {
  border: 0;
}

.react-contextmenu div + div {
  border-top: 2px solid #767676;
}

.store-totals-context-menu {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .button {
    padding: 12px;
    margin-left: 12px;
    margin-right: 12px;
    border: 2px solid #767676;
    border-radius: 4px;
  }

  .button:first-child {
    margin-top: 12px;
  }
}

.advanced-options-dates {
  display: flex;
  flex-direction: column;
}

.advanced-options-row {
  display: flex;
  flex-direction: row;
  width: 60%;
  font-size: 14px;
  justify-content: stretch;

  .pair {
    display: flex;
    flex-direction: row;
  }

  .search-totals-label {
    font-weight: bold;
  }

  input {
    font-size: 14px;
  }

  .retailer-id-totals {
    margin-left: 5px;
    width: 50%;
    height: 44px;
    border-radius: 0;
    display: flex;
    align-content: flex-end;
  }

  .id-input {
    height: 40px;
    margin-left: 5px;

    button {
      margin: 10px;
    }
  }

  #edit-show-search-total-modal {
    margin-left: 80px;
  }
}

.divider {
  margin: 12px 0;
  border-left: 1px solid #D9D9D9;
}

.nav-title-bar {
  width: 90%;
  display: flex;
  flex-direction: row;
}

.chevron-down {
  padding-left: 8px;
  width: 20px;
  height: 20px;
}

.menu {
  .is-active > a {
    background-color: white;
    color: #666666;
  }
}

.is-dropdown-submenu {
  min-width: 150px;
  border-radius: 4px;

  .is-dropdown-submenu-item:hover {
    background-color: #F2F2F2;

    & > a {
      background-color: #F2F2F2;
    }

    .dropdown-menu-link-large {
      color: #666666;
    }
  }

  .is-dropdown-submenu-item:active {
    background-color: #E6F5FF;
  }
}

.side-nav-dropdown {
  margin-left: 16px;
  width: 300px;
  min-width: 275px;
}

.total-dollars-cell-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.data-row-dropdown .data-row-cell-select__control {
  border-color: #b1b1b1;
  margin-bottom: 1rem;
  height: 34px;
  min-height: 34px;
}

.data-row-dropdown .data-row-cell-select__indicator-separator {
  display: none;
}