.react-table-dropdown-filter-container {
  width: 100%;
  font-size: 14px;
  font-weight: normal;

  div[class$="-filter-dropdown__input"] {
    input {
      box-sizing: border-box !important;
      border-radius: 3px !important;
      height: 24px !important;
      padding: 0 !important;
      background: 0 center !important;
      border: 0 !important;
    }
  }

  .alert_type-filter-dropdown__value-container, .active_retailer-filter-dropdown__value-container,
  .designation_type-filter-dropdown__value-container, .problem_weeks-filter-dropdown__value-container,
  .closed-filter-dropdown__value-container,
  .deliverable-filter-dropdown__value-container, .schedule-filter-dropdown__value-container, .tableName-filter-dropdown__value-container {
    min-height: 32px !important;
    max-height: 32px;
  }

  div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
    min-height: 32px !important;
    max-height: 32px;
  }

  div[class$="-Input"] {
    min-height: 32px !important;
    max-height: 32px;
    padding: 0;
  }

  div[class$="-control"] {
    min-height: 32px !important;
    max-height: 32px;
  }

}