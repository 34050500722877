// Global styles

@import "settings";
@import "../../node_modules/foundation-sites/scss/foundation";

@include foundation-everything;

@import "../../node_modules/react-dropdown/style.css";
@import "../../node_modules/react-datepicker/dist/react-datepicker.css";

@import "variables";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";

@import '../../node_modules/react-tabs/style/react-tabs.scss';

.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 101;
}

.App-uat {
  background-color: darkgray;
}

.App-prod {
  background-color: black;
}

.App-dev {
  background-color: lightgray;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 24px;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  margin-bottom: 16px;
}

h2 {
  font-size: 16px;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  margin-bottom: 16px;
}

main {
  //display: inline-flex;
  padding: 20px;
  flex-direction: column;
  //align-items: flex-start;
  gap: 10px;

  //margin: 16px 36px;
  display: flex;

}

.button.primary {
  font-weight: 700;
  background: var(--blue-80, #1271B2);
}

.button.submit {
  font-weight: 700;
  color: #FFFFFF;
}
.button.alert.submit {
  font-weight: 700;
  color: #252525;
}

.button.cancel {
  font-weight: 400;
  color: #252525;
}

.select-dropdown {
  width: 100%;

  .select-dropdown__value-container {
    min-height: 40px !important;
    max-height: 40px;
  }

  div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
    min-height: 40px !important;
    max-height: 40px;
  }

  div[class$="-Input"] {
    min-height: 40px !important;
    max-height: 40px;
    padding: 0;
  }

  div[class$="-control"] {
    min-height: 40px !important;
    max-height: 40px;
  }
}

.search-dropdown-container {
  width: 200px !important;
  .id-input {
    width: 200px;
    padding: 0 8px 0 0;
  }
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-horizontal-center {
  display: flex;
  justify-content: center;
}


// Our styles. At the bottom so they take precedence over the "global" styles above
@import './designationDetails';
@import './measuresRelease';
@import './retailerFiles';
@import './breadcrumbs';
@import './dataTable';
@import './triggerDag';
@import './marketService';
@import './ingestionLogs';
@import './items_summary';
@import './checkpointSpotlight';
@import './ftpConfigurations';
@import './fillAllMultipleRetailers';
@import './buttonsAndModals';
@import './unmappedRetailers';
@import './dropdownsAndMenus';
@import './errors';
@import './extractSpotlight';
@import './alerts';
@import './sourceFilesReport';
@import './header';
@import './titleDropdown';
@import './loadingSpinner';
@import './unauthorized';
@import './confirmationModal';
@import './measuresTriggerDag';
@import './retailerFill';
@import './forms';
@import './readiness';
@import './copyMarketServicePeriod';
@import './taskDetails';
@import './reactTable';
@import './badges';
@import './icons';
@import './storeTotalsTotalDollars';
@import './collapsableContent';
@import './page';
@import './nameValueLabels';
@import './retailerManagementPage';
@import 'datasetsCheckboxes';
@import './storeTotals';
@import './retailerTotals';
@import './reactContextMenu';
@import './keith';
@import './googleMaps';
@import './toggleRadioButtons';
@import './_legacyLoadCompleted';
@import './_modal';
@import './_system';
@import './knownItemPercentage';
@import './scaffolding';
@import './selectableList';
@import './sldSpotlight';
@import './reactTable7';
@import './reactSelectDropdowns';
@import './scaffoldingPublish';
@import './search';
@import './toast';
@import './keys';
@import 'missingData';
@import 'retailerHighlightedEvents';
@import 'projectionFactorsService';
