width-450 {
  width: 450px;
}

.dag-trigger-group {
  .custom-run-selections {
    flex-direction: row;
    display: flex;

    .spotlight-divider {
      height: 80px;
      width: 1px;
      background-color: #ccc;
    }

    .custom-run-selections-column {
      flex-direction: column;
      display: flex;
      margin-left: 36px;

      .projections-version-row {
        display: flex;
        align-items: center;

        .get-test-projections-submit {
          display: flex;
          height: 42px;
          margin-left: 16px;
        }

        .last-run-time-stamp {
          font-weight: bold;
        }
      }
      .custom-run-selections-row {
        flex-direction: row;
        display: flex;

        .custom-run-selections-row-column{
          flex-direction: column;
          display: flex;
        }
      }


      .scaffolding-dag {
        margin-right: 0;
        display: block;
      }

      .measures-item-filters, {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        height: 45px;

        #check-boxes {
          border: 1px solid #cacaca;
          align-items: center;
        }
      }
    }
  }

  .measures-checkpoint-container {
    flex-direction: column;
    display: flex;
    margin-left: 36px;
  }

  .switches-radio {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-left: 4px;
    padding: 16px;
  }
}