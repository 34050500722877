:is(body, html, #root):has(.missing-data) {
  height: 100%;
  background-color: #fbfbfb;
}

.missing-data {
  height: 100%;
  width: 100%;

  main {
    background-color: #fbfbfb;
    margin: 0;
    padding: 16px 36px;
    height: 100%;
  }

  section {
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0 1px 3px 0 rgba(37, 37, 37, 0.15), 0 1px 0 0 rgba(37, 37, 37, 0.05);
  }

  .title-and-submit {
    margin-top: 0;
    height: 32px;
    width: 100%;
    flex-shrink: 0;

    div {
      font-feature-settings: 'clig' off, 'liga' off;
      display: flex;
      padding: 0 20px;
      align-items: flex-start;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      flex-grow: 1;
    }

    .button {
      border-radius: 4px;
      background: #1271B2;
      display: inline-flex;
      padding: 6px 16px;
      align-items: center;
      gap: 10px;
      margin: 0 20px;
    }

    display: flex;
  }

  .react-tabs {
    width: 100%;
  }

  .action-buttons-and-filter-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #E2E2E2;
    padding: 20px 0 20px 8px;

    .filters {
      margin-top: 24px;
      margin-left: -32px;

      .missing-data-select-filters {
        padding: 16px 0 0 0;
      }

      .filter-summary {
        display: flex;
        padding: 0 20px;
        align-items: center;
        align-content: center;
        gap: 12px;
        flex: 1 0 0;
        flex-wrap: wrap;
        border-left: 1px solid var(--Neutral-50, #B1B1B1);

        .select-filters {
          display: flex;
          width: 32px;
          height: 32px;
          padding: 8px;
          margin: 0;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: #E9E9E9;
        }
      }
    }
  }
}

.react-table-wrapper {
  .action-buttons {
    display: flex;
    padding: 20px 20px;
    align-items: center;
    align-content: center;

    button {
      display: flex;
      align-items: center;
      border-radius: 4px;
      background: #1271B2;
      margin-right: 8px;
    }
  }
}

.title-object {
  display: flex;
  align-items: center;

  .export-report-button {
    color: #474747;
    background: #E9E9E9;
    font-weight: 700;

    .export-report-icon {
      margin-right: 8px;
    }
  }

  .title-object-divider {
    height: 40px;
    width: 1px;
    background-color: #ccc;
    margin: 0 20px 0 20px;
  }

}

.missing-data-modal-header {
  font-size: 16px;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  justify-content: space-between;
}

.missing-data-export-options {
  width: 940px;
  height: 440px;
  border-radius: 3px;
  background: #FFF;
  border-bottom: 1px solid #D9D9D9;
  //box-shadow: 0px 1px 3px 0px rgba(37, 37, 37, 0.15), 0px 1px 0px 0px rgba(37, 37, 37, 0.05);
  display: flex;
  flex-direction: row;

  .export-options-group {
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
  }

  .export-options-group > button {
    display: flex;
    width: 230px;
    height: 40px;
    padding: 5px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin: 0;
  }

  .export-options-group > button.selected {
    background: #E6F5FF;
    font-weight: bold;
  }

  .export-options-group:not(:last-child) {
    border-right: 1px solid #D9D9D9;
  }

  .radio {
    width: 325px;
  }

  .filter-summary {
    padding: 0 20px;
    display: flex;
    width: 250px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.missing-data-export-header-note {
  display: flex;
  width: 710px;
  padding: 0 20px 20px 20px;
  align-items: center;
}

*:is(.missing-data,.missing-data-export-options) {

  .h6 {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body Large Heavy */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }

  .chip {
    display: inline-flex;
    padding: 6px 16px;
    align-items: center;
    gap: 8px;

    border-radius: 16px;
    border: 1px solid #B1B1B1;
    background: #FFF;

    button {
      margin: 0;
    }
  }

  // .chip

  .id-input {
    width: 215px;
  }
}


.date-filter-options {
  display: flex;
  flex: 0 1 0;
  flex-direction: column;
  align-items: flex-start;
}

.custom-date-notice {
  font-size: 14px;
}

input:invalid {
  background-color: #ffdddd;
}

.missing-data-date-group {
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  flex-direction: column;

  h6 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .missing-data-date-select-dropdown {
    width: 320px;
    height: 34px !important;
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: normal;

    div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
      height: 28px !important;
    }

    div[class$="-Input"], .missing-data-date-select-dropdown__input {
      height: 28px !important;
      padding: 0;

      input {
        height: 28px !important;
        padding: unset !important;
        font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      }
    }

    div[class$="-control"] {
      border-color: #B1B1B1 !important;
      border-radius: 3px !important;
      border-style: solid;
      height: 28px !important;
    }

    div[class$="-ValueContainer"], div[class$="_value-container"], .missing-data-date-select-dropdown__value-container {
      height: 28px !important;
      overflow: unset;
    }
  }

  .missing-data-date-pickers {

    .custom-date-notice {
      padding-top: 20px;
    }

    .missing-data-date-pickers-section {
      display: flex;

      .missing-data-date-pickers-from-section, .missing-data-date-pickers-to-section {
        display: flex;
        flex-direction: column;
        margin-right: 8px;
        width: 320px;
      }
    }
  }
}

.missing-data-select-filters {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  h6 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }



  .filter-group {
      display: flex;
      padding: 0 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      background: #FFF;
      width: 325px;

      .missing-data-multi-select-dropdown {
        min-height: 20px;
        width: 100%;
        font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-weight: normal;

        div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
          min-height: 32px !important;
        }

        div[class$="-Input"], .missing-data-multi-select-dropdown__input {
          height: 20px !important;
          padding: 0;

          input {
            height: 24px !important;
            padding: unset !important;
            font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
          }
        }

        div[class$="-control"] {
          border-color: #B1B1B1 !important;
          border-radius: 3px !important;
          border-style: solid;
          min-height: 32px !important;
        }

        div[class$="-ValueContainer"] {
          min-height: 32px !important;
          overflow: unset;
        }

        div[class$="select-dropdown__multi-value"] {
          background-color: #F2F2F2 !important;
          color: #252525 !important;
        }

        .missing-data-multi-select-dropdown__option {
          background-color: #FFF !important;
          color: #0a0a0a !important;

          .missing-data-multi-select-dropdown-option-label {
            display: flex;
            font-size: 14px;
            font-style: normal;
            line-height: 20px;
            width: 100%;

            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .missing-data-label {
              display: flex;
            }

            .missing-data-active-icon {
              display: flex;
            }
          }

          .missing-data-multi-select-dropdown-option-label.is-selected {
            font-weight: bold;
          }
        }
      }
    }

  .radio {
    margin-top: 8px !important;
    width: 215px;
  }

  .radio-button-label-true {
    display: flex;
    width: 215px;
    padding: 6px 16px;
    align-items: flex-start;
    gap: 16px;

    border-radius: 4px;
    border: 1px solid #D9D9D9;
    background: #F4F6F8;
  }

  .radio-button-label-false {
    display: flex;
    width: 215px;
    padding: 6px 16px;
    align-items: flex-start;
    gap: 16px;

    border-radius: 4px;
    border: 1px solid #D9D9D9;
  }

  .radio-button-label-text {
    flex: 1 0 0;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body */
    //font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
