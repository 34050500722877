.inactive-retailer-row-ftp {
  background: #E2E2E2 !important;

  .table-cell-actions {
    background: #E2E2E2 !important;
  }
}

.table-cell-ingestionButtons {

  .run-fill {
    margin-right: 8px;
  }

  .horizontal-control-block-element {
    input {
      height: 32px;
    }

    .button.secondary {
      margin-left: 8px;
      height: 32px;
    }
  }
}

.react-table-popover-wrapper {
  background: #fefefe !important;
  border: 1px solid #B1B1B1;
  padding: 8px;
  width: 150px;

  .popover-content {
    margin: unset;
    .ftp-configs-modal-button, .ftp-config-load-menu-buttons, .stage-to-archive-button {
      padding: 4px;
      white-space: normal;
      button {
        margin:4px
      }
    }
  }
}

.react-table-wrapper {
  #switches {
    margin-bottom: 32px;

    .run-all-retailer-dags {
      align-items: center;
      align-self: flex-start;
      display: flex;

      button, input {
        margin: 0 16px 0 0;
      }

      .divider {
        height: 24px;
      }

      button {
        white-space: nowrap;
      }

      .checkbox-container {
        display: flex;
        align-items: center;

        .checkbox-label {
          display: flex;
          flex-direction: column;
          margin-top: 0;
          width: 100px;
        }

        .checkbox {
          display: flex;
          flex-direction: column;
          margin-right: unset;
          align-self: center;
        }
      }

      .tooltip-icon {
        display: flex;
        align-items: center;
      }
    }
  }
}