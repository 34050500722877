.fill-all-retailer-dag {
  display: flex;
}

.date-fill-multi {
  margin: 0 16px 0 0;
  max-width: 220px;
  display: flex;
  max-height: 50px;
}

.submit-fill-multi{
  max-width: 100px;
  max-height: 50px;
}