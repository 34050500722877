.spotlight-selection-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  .column {
    display: flex;
    flex-direction: column;

    #check-boxes {
      justify-content: center;
    }
  }

  .schema-selection {
    display: flex;
    flex-direction: column;
  }

  .schema-selection-row {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
  }

  .threshold-selection {
    display: flex;
    flex-direction: row;
  }

  .threshold-selection {
    margin: 18px 0;
  }

  #threshold-dollars, #threshold-units, #threshold-percent-difference {
    width: 80px;
    height: 32px;
    margin: 0;
  }

  .threshold-field, .schema-field {
    display: flex;
    margin-right: 20px;
    align-items: center;

    span {
      margin-right: 10px;
      font-size: 14px;

    }

  }

  #from-schema-dropdown, #to-schema-dropdown {
    margin: 0 20px 0 20px;
  }
}

.spotlight-header {
  font-size: 12px;
}

.spotlight-divider {
  height: 80px;
  margin: 0 20px 0 10px;
  width: 1px;
  background-color: #ccc;
}

.submit-spotlight {
  margin-top: 20px;
  display: flex;

  button {
    flex-direction: row;
  }
}

.spotlight-table, .drill-down-table {
  margin: 16px 0 0 0;
}

.spotlight-percentage-red {
  color: get-color(red-60);
}

.spotlight-header-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  border-top: #ccc 1px solid;
  margin-top: 20px;
  padding-top: 20px;

  .all-retailers-sum {
    .sum-label {
      font-weight: bold;
    }

    .sum-data {
      margin: 0 20px 0 5px;
    }
  }
}

.spotlight-table {
  .tab-download-csv {
    display: flex;
    justify-content: flex-end;
  }
}

.error-div {
  background-color: get-color(alert);
  padding: 24px;
  margin: 16px;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;

  .fa, .fas {
    padding-left: 8px;
  }
}