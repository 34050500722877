form:not(.radio-button-group-form) {

  label {
    color: var(--neutral-100, #252525);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body */
    //font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    margin-top: 12px;
  }

  input {
    display: flex;
    height: 32px;
    padding: 6px 12px;
    align-items: center;
    align-self: stretch;

    /* Body */
    //font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    border-radius: 4px;
    border: 1px solid var(--neutral-50, #B1B1B1);
    background: var(--neutral-0, #FFF);

    margin-bottom: 0;
  }

  input.disabled, input[readonly], textarea.disabled, textarea[readonly] {
    background-color: #e6e6e6;
    cursor: not-allowed;
  }

  select {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin-bottom: 0;
  }

  .error {
    color: var(--red-80, #CF1220);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body Small */
    //font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */

    margin-top: 4px;
  }
}

.id-input {
  width: 400px;
  display: inline-flex;
  font-size: 14px;
  font-weight: unset;

  button {
    margin: 10px;
  }
}

.help-text {
  margin-top: 5px;
  color: #767676;
}

.scaffolding-input {
  width: 800px;
  font-size: 14px;
  font-weight: unset;
}

.scaffolding-dag {
  margin-right: 20px;
  display: block;
}

.input {
  width: 150px;
  height: 40px;
  display: inline-block;
}

.scaffolding-submit {
  display: block;
  margin-top: 8px;
}

.select-period {
  align-items: center;

  display: inline-flex;
  font-size: 14px;
  font-weight: normal;
}

.data-row-dropdown {
  width: 100%;

  .Dropdown-control {
    height: 100%;
  }

  .Dropdown-menu {
    overflow: scroll;
  }
}

.add-unmapped-store-button {
  margin: 15px auto;
}

#input-group {
  padding-bottom: 12px;
}

.button {
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 4px;
}

.optional-dropdown {
  min-width: 300px;
  width: 100%;

  div[class$="__input"] {
    input {
      box-sizing: border-box !important;
      border-radius: 3px !important;
      height: 24px !important;
      padding: 0 !important;
      background: 0 center !important;
      border: 0 !important;
    }
  }

  .optional-dropdown__value-container {
    min-height: 40px !important;
    max-height: 40px;
  }

  div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
    min-height: 40px !important;
    max-height: 40px;
  }

  div[class$="-control"] {
    min-height: 40px !important;
    max-height: 40px;
  }

  div[class$="-ValueContainer"] {
    min-height: 40px !important;
    max-height: 40px;
  }
}

.large-optional-dropdown {
  min-width: 800px;
}

.draw-a-line {
  padding: 0;
  margin: 0 0 15px 0;
}

.regular-select{
  .selector {
    min-width: 200px;
  }
}

.box__control{
  border-radius: 0 2px 2px 0 !important;
}

.box__value-container{
  height: 44px;
}

input#react-select-2-input {
  height: 33px;
}

.button {
  .fa-spinner {
    margin-right: 5px;
  }
}

.Dropdown-disabled {
  background-color: #e6e6e6;
}

.switches-radio {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding-left: 30px;
  font-size: 12px;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: normal;

  .radio {
    padding: 8px;
    display: inline-flex;

    label {
      margin-right: 16px;
    }
  }
}

button.link {
  line-height: inherit;
  color: #1779ba;
  cursor: pointer;
}

button.link:disabled {
  color: lightgray;
}

.missing-nucleus-extract-promo-checkbox {
  margin-top: 16px;
  display: flex;
}