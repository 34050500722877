.google-map-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
  user-select: none;
  transform: translate(-50%, -50%);
  z-index: 100000
}

.google-map {
  height: 25vh;
  width: 100%;
}

.edit-address {
  display: flex;

  .edit-address-button {
    margin-left: 8px
  }

}