.retailer-files-no-data-found {
  border: 1px solid grey;
  text-align: center;
  background: whitesmoke;
  padding: 8px;
  border-radius: 4px;
  font-weight: 600;

  a {
    color: black;
  }
}