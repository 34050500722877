
.input-new-period {
  display: flex;
  width: 90%;
  align-content: flex-end;
  margin-left: 16px;
}

.create-period-button, .json-compare {
  vertical-align: top;
  align-self: flex-end;
}