#market-service-page {
  .current-period-label {
    font-weight: bold;
    margin-right: 16px;
  }

  .market-service-page-controls {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .left-things {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #E2E2E2;
      padding-bottom: 12px;
      margin-bottom: 16px;

      .period-selection {
        height: 48px;
        display: flex;
        align-items: center;
        margin-right: 16px;
        border-right: 1px solid #E2E2E2;
      }

      .compare-period-json {
        display: flex;
        background-color: white;
      }

      .market-service-icons {
        display: flex;
        background-color: white;
      }
    }

    .notification-button {
      margin-left: 16px;
      display: flex;
      justify-content: flex-end;
      color: white !important;
    }

    .dropdown-wrapper {
      display: flex;

      width: 150px;
      margin: 0 16px;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .add-record-button {
    width: 150px;
  }

  .delete-button {
    margin-left: 16px !important;
  }

  button, .button {
    margin: 0;
    color: #1779ba !important;
  }
}

.market-service-content-controls {
  display: flex;
  align-items: center;
  margin: 8px 0 16px 0;

  button {
    margin: 0 16px 0 0;
  }

  .duplicate-warning-message {
    align-self: center;
    font-weight: bold;
    color: get-color(red-60);
  }
}

.icon-dropdown-ul {
  z-index: 999;
  margin: 0;
  padding: 0;
  position: absolute;
  float: none;
}

.icon-dropdown-li {
  background-color: white;
  position: relative;
  list-style-type: none;
  padding: 4px;
  outline: 1px solid lightgray;
}

.fa-compress-alt:hover, .fa-copy:hover, .fa-download:hover, .fa-upload:hover, .fa-trash-alt:hover, .fa-edit:hover, .fa-cog:hover, .fa-save:hover {
  background-color: lightgray;
  color: #1779ba;
}

.fa-compress-alt, .fa-copy, .fa-download, .fa-upload, .fa-trash-alt, .fa-cog, .fa-edit, .fa-save {
  color: #495057;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}

.selected-icon {
  background-color: lightblue;
}

.button#download-as-csv {
  width: 100%;
  border-width: 0;
}

.button#download-as-csv:hover {
  background-color: lightgray !important;
  color: #1779ba !important;
}

.modal-header {
  font-size: 16px;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.DeleteModal {
  position: absolute;
  width: 480px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  & div {
    font-size: 12px;
  }
}


.table-cell-actions, .table-cell-market_key, .table-cell-index, .table-cell-description {
  background: #f4f6f8;
}

.market-service-edit-modal {
  overflow: scroll;
  min-width: unset;
  max-width: unset;
  width: 75%;

  .edit-modal-data-row {

    .edit-form {
      .edit-form-row {
        width: 50% !important;
        padding: 0 8px;
      }

      .edit-row-market_key, .edit-row-index {
        width: 100% !important;
      }

    }

  }


}
