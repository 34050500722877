body .ReactTable .rt-th {
  white-space: unset;
  background: #F4F6F8;
  font-weight: bold;
  box-shadow: inset -1px 0px 0px #D9D9D9;

  input {
    background: #FFFFFF !important;
    border: 1px solid #B1B1B1 !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    height: 32px;
    width: 100%
  }

}


body .ReactTable .rt-tr-group:nth-of-type(even) {
  background: #fafafa;
}

body .ReactTable .rt-td {
  white-space: unset;
  padding: unset;
}

body .ReactTable .numeric-field {
  text-align: end;
  padding: 4px 8px;
}

body .ReactTable .display-linebreak {
  white-space: pre-line;
}

body .ReactTable .non-numeric-field {
  text-align: start;
  padding: 4px 8px;
}

body .ReactTable {
  text-align: center;
}

.inactive-retailer-row {
  background-color: #dbd3d3;
}

.inactive-store-row {
  background-color: #dbd3d3;
}

