.ops-alerts-content {
  .rt-th {
    overflow: visible;
  }
}

.acknowledge-all {
  margin: 0 0 0 0.5rem;
}

.not-outlier {
  background-color: get-color(green-20);
}

.outlier-severity-1 {
  background-color: get-color(yellow-20);
}

.outlier-severity-2 {
  background-color: get-color(orange-20);
}

.outlier-severity-3 {
  background-color: get-color(red-20);
}

.outlier-severity-4 {
  background-color: get-color(red-60);
  color: white;
}

.outlier-cell {
  height: 100%;
  width: 100%;
}

.alert-summary {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  .alert-group {
    margin-right: 40px;
  }
}

.alerts-selections {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.alert-payload-modal {
  max-width: 90%;
  max-height: 80%
}

.jira-alert-button {
  margin-left: auto;
}

.alerts-divider {
  border-left: 1px solid #ccc;
}

.data-issue-border div[role="cell"]:is(:first-child)  {
  border-left: 8px solid #FA7978;
  padding-left: 4px;
}

.no-data-issue-border div[role="cell"]:is(:first-child)  {
  border-left: 8px solid #C7C7C7;
  padding-left: 4px;
}

.further-review-border div[role="cell"]:is(:first-child) {
  border-left: 8px solid #FADB7A;
  padding-left: 4px;

}

.alert-modal-saved-classification {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  margin-top: 8px;
}

.alert-modal-saved-classification-highlight {
  margin-left: 4px;
  border-radius: 4px;
  padding: 4px 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.highlight-pink {
  background: #fdc2c2;
}
.highlight-light {
  background: #e2e2e2;
}
.highlight-yellow {
  background: #f9eabf;
}


.alert-actions {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  width: calc(100% + 16px);
  margin: 0 -8px;

  .divider {
    width: 1px;
    height: calc(100% + 16px);
    margin: -8px 0;
  }

  .alert-single-action:hover .toolTipText {
    visibility: visible;
  }

  .alert-single-action {
    display: flex;
    flex-direction: row;

    .header-button {
      margin-bottom: unset;
    }

    .fa-edit:hover, .fa-cog:hover, .fa-check:hover, .fa-undo:hover {
      background-color: lightgray;
      color: #1779ba;
    }

    .fa-compress-alt, .fa-copy, .fa-download, .fa-upload, .fa-trash-alt, .fa-cog,
    .fa-edit, .fa-check, .fa-undo {
      color: #495057;
      padding: 4px 2px 4px 2px;
      border-radius: 4px;
      cursor: pointer;
    }

  }

  .open-json-payload-modal {
    display: unset;
    height: 100%;
    align-items: center;
    justify-content: center;

    .button {
      margin-bottom: unset;
    }
  }


}

.open-json-payload-modal-buttons {
  display: flex;
  margin-top: 10px;
}


.retailer-status-group {
  display: flex;
  flex-basis: 25%;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;

  h5 {
    margin-bottom: 0;
  }

  .retailer-status-label{
    color: #000;
    text-align: center;
    align-items: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    .checkbox-checkboxes {
      display: flex;
    }


    .checkbox-header {
      font-family: sans-serif;
    }
  }

  .retailer-status-checkboxes-container {
    display: flex;
    flex-direction: column;

    #check-boxes {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .checkbox-container {
        display: flex;
        align-items: center;
        font-size: 12px;
        text-align: left;
        font-family: sans-serif;

        .Dropdown-control {
          margin-left: 8px;
          margin-right: 16px;
        }

        .checkbox-filters-label {
          left: 20px;
          top: 22px;
          font-size: 14px;
          line-height: 28px;
        }

        .checkbox-label {
          background-color: #E9E9E9;
          border: 1px solid #ccc;
          border-radius: 20px;
          cursor: pointer;
          height: 32px;
          text-align-last: center;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 2px;
          margin: 0 0.5rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 10em;
        }

        .checkbox-label:before {
          content: '✓';
          line-height: 1em;
          width: 1em;
          height: 1em;
          text-align: center;
          opacity: 0;
          display: none;
        }

        input[type="checkbox"] {
          visibility: hidden;
          margin-bottom: unset;
          margin-left: 10px;
          display: none;
        }

        input[type="checkbox"]:checked + .checkbox-label {
          background-color: #666666;
          border-color: #666666;
          color: #FFFFFF;
          font-weight: bold;
        }

        input[type="checkbox"]:checked + .checkbox-label:before {
          opacity: 1;
          display: inline-block;
        }

      }
    }
  }

  .alert-divider {
    display: flex;
    flex-direction: column;
    height: 60px;
    width: 1px;
    background-color: #ccc;
  }

  .fake-button-for-show {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}


