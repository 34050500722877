.ignore-icon {
  height: 35px;
  width: 35px;
}

.table-input-field {
  width: 200px;
  margin-top: 1px;
  margin-right: 16px;
}

.unmapped-retailer-add-mapping {
  .input-button-container {
    display: flex;
    flex-flow: wrap;

    input {
      height: 32px;
    }

    .button.secondary {
      margin-left: 8px;
      height: 32px;
    }
  }
}

.unmapped-retailers-table {
  .rt-table {
    z-index: 1;
  }

  .-pagination {
    z-index: unset !important;
  }

  .pagination-number-of-rows-select-dropdown__menu {
    z-index: 2;
  }
}
