.scaffolding-publish-modal {
  width: 500px;
  min-height: 200px;
  max-height: 850px;

  .textarea {
    height: 600px;
  }
}

.scaffolding-publish-button {
  margin: 0;
}