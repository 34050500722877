.used-file {
  color: #397A11;
}

.file-name {
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.source-files-column {
  display: flex;
  justify-content: flex-end;

  .source-file-action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 15px;
  }
}
