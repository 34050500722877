.retailer-icon {
  width: 20px;
  padding-left: 2px;
}

.icon-spacing {
  margin-right: 8px;
  float: left;
}

.report-icon {
  margin-right: 8px;
  float: right;
  padding: 4px 0 0 8px;
  color: #495057;
}

.cleanse-icon {
  width: 24px;
  height: 24px;
  padding: 0 !important;
}

.fa-bars {
  font-size: 20px;
  color: #F2F2F2;
}