.conventional-data-unload {
  .circana-data-unload-checkbox-container {
    padding: 20px 0 20px 0;
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;

    .unload-status-title {
      padding-bottom: 15px;
    }
  }

  .circana-action-button {
    background-color: #E6E6E6;
    border-radius: 4px;
    margin: 8px 0 0 0;
    height: 30px;
    width: 30px;
  }

  .unload-status-text {
    margin-left: 4px;
  }

  .copy-conventional-path-button {
    margin-right: 8px;
  }
}