.legacy-load-completed-page {
  display: flex;
  flex-direction: column;

  .button {
    width: 160px;
  }
}

.legacy-load-completed-page > div,button {
  margin-bottom: 12px;
}