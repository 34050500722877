.name-value-labels {
  display: flex;
  justify-content: flex-start;
}

.page-header {
  .name-value-labels {
    display: flex;
    justify-content: space-evenly;
  }
}

.name-value-label {
  display: flex;
  justify-content: flex-start;
  padding-right: 32px;
  font-family: sans-serif;
  font-weight: normal;

  :first-child {
    padding-right: 8px;
  }
}

.name-value-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
