.modal-x-close {
  float: right;
  display: flex;
}

.ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.section-header {
  font-weight: bold;
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
}

.editor-and-buttons-side-by-side-container {
  display: flex;
  flex-direction: row;

  .modal-component-divider {
    height: auto;
    margin: 35px 20px 35px 20px;
    width: 1px;
    background-color: #ccc;
  }
}

.buttons-container {
  display: flex;
  flex-direction: column;
}

.modal-header {

  .modal-header-title-container {
    margin-bottom: 12px;

    .modal-header-title-text {
      font-family: Roboto, Helvetica Neue, Arial, sans-serif;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.modal-alerts-text-area-wide {
  margin-top: 8px;
  height: 125px;
  width: 425px;
  resize: vertical;
}

.alert-modal-task-name,  .alert-retailer-label {
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  margin-top: 8px;
}

.modal-radio-button-group-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .radio {
    margin: 16px 0 0 0 !important;

    label {
      width: 100%;
      display: flex;
      flex-direction: row;
      border-color: #b1b1b1;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      padding: 8px;

      .radio-button-label-text {
        margin-left: 8px;
      }

      .radio-button-input {
        display: none !important;
      }

      .fa-check-circle {
        align-self: center;

        &.check-icon-false {
          color: #C7C7C7;
        }

        &.check-icon-true {
          color: #1271B2;
        }
      }

    }
  }

  .radio-button-label-true {
    background: #F4F6F8;
  }

  label:hover {
    background: #F4F6F8;
    cursor: pointer;
  }

  .radio {
    .radio-button-input {
      margin: 0 4px 0rem !important;
      content: '✓'
    }
  }
}

.alert-modal-highlighted-events-container {
  display: flex;
  flex-direction: column;
  padding: 8px 0 0 0;

  .alert-modal-highlighted-events-header {
    flex-direction: row;
    display: flex;
  }

  .alert-modal-highlighted-events-badges {
    padding: 8px 0 0 0;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;

    .highlighted-event-badge {
      color: black;
      background-color: #E2E2E2;
      border: 1px solid black;
      border-radius: 20px;
      cursor: pointer;
      height: 32px;
      text-align-last: center;
      padding: 2px 16px 0 16px;
      margin: 4px 0.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

}
