.name-value-labels {

  &.last-updated {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  .reload-items-summary{
    margin: 0;
  }
}