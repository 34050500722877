.badge.alert {
  margin-left: 5px;
  padding: 0.5em;
}

.badge.issue-badge {
  margin-left: 5px;
  background-color: get-color(red-60);
  vertical-align: top;
}
.badge.non-issue-badge {
  margin-left: 5px;
  background-color: unset;
  vertical-align: top;
}


.issue-link-color-red {
  color: #e32836
}