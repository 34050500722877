.add-edit-modal {
  width: 360px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #FFF;
  box-shadow: 0px 1px 3px 0px rgba(37, 37, 37, 0.15), 0px 1px 0px 0px rgba(37, 37, 37, 0.05);

  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  //border: 1px solid rgb(204, 204, 204);
  //background: rgb(255, 255, 255);
  overflow: auto;
  //border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;

  h1 {
    color: var(--neutral-100, #252525);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body Large */
    //font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */

    margin-bottom:8px;
  }

  .modal-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 20px;
    right: 20px;

    button {
      margin: 8px;
    }

    button:last-of-type {
      margin-right: 0;
    }
  }

}

.duplicated {
  background: get-color(alert);
}

.horizontal-control-block {
  display: flex;
  flex-direction: row;
}

.retailer-id-element {
  margin-top: 24px;
}

.rollup-weekly-button .reload-archive-button .past-due-downloads {
  width: 20%
}

.update-retailer-id {
  width: 30%
}

.horizontal-control-block-element {
  justify-content: center;
  display: flex;
  margin-left: 8px;
}

.horizontal-control-block-element-left {
  justify-content: left;
  display: flex;
  margin-left: 8px;
}

.run-all-retailer-dags {
  align-items: center;
  align-self: flex-start;
  display: flex;

  button, input {
    margin: 0 16px 0 0;
  }
  .divider {
    height: 24px;
  }

  button {
    white-space: nowrap;
  }
}

.popover-row-content {
  display: flex;
}

.popover {
  width: 150px
}

.confirmation-modal {
  height: 120px;
  width: 420px;
}

.confirmation-modal-body {
  padding: 0 0 0 16px;
  font-family: Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  overflow: auto;
  max-height: 700px;
}

.button-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.normalized-report-actions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .button {
    margin: 0;
    white-space: nowrap;
  }
}

.archive-file-name-modal {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .file-name{
    margin: 0 0 0 5px
  }
}

.show-file-partial-modal {
  max-width: 80%;
  max-height: 80%;
}

.file-partial-content {
  background-color: #cacaca;
  font-family: Courier, Roboto, Arial, sans-serif;
  padding: 8px;
  white-space: pre;
  border-radius: 4px;
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 480px;
}

.ftp-configs-modal {
  min-width: 400px;
}

.clickable-thingy {
  cursor: pointer;
}

.dag-trigger-group {
  display: flex;
  flex-direction: column;

  .button {
    width: 75px;
  }

  .sld-trigger-button {
    width: 150px;
  }

  .promo-trigger-button {
    width: 180px;
    margin-left: 8px;
    height: 60px;
  }
}

.dag-trigger-group-row {
  display: flex;
  flex-direction: row;

  .button {
    width: 75px;
  }

  .promo-trigger-button {
    width: 180px;
    margin-left: 8px;
    height: 60px;
  }
}

.projections-version-row {
  .dag-trigger-group .button {
    width: unset;
  }

  .button {
    margin-bottom: unset;
  }

  .get-test-projections-submit {
    width: 170px;
    margin-left: 1em;
  }

  .id-input {
    min-width: 300px;
    width: 100%;

    div[class$="__input"] {
      input {
        box-sizing: border-box !important;
        border-radius: 3px !important;
        height: 24px !important;
        padding: 0 !important;
        background: 0 center !important;
        border: 0 !important;
      }
    }

    div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
      min-height: 40px !important;
      max-height: 40px;
    }

    div[class$="-control"] {
      min-height: 40px !important;
      max-height: 40px;
    }

    div[class$="-ValueContainer"] {
      min-height: 40px !important;
      max-height: 40px;
    }
  }
}

.totals-modal-column-wrapper-outer{
  width: 550px;
}

.totals-modal-toggle-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-top: 24px;
}

.totals-modal-row-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .id-input {
    width: 200px;
  }
}

.totals-modal-row-wrapper-state {
  display: flex;
  flex-direction: row;

  .id-input {
    width: 200px;
  }

  .totals-modal-column-wrapper-state{
    margin-left: 52px;
  }
}

.totals-modal-column-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#basic-options-label {
  padding-left: 8px;
}

.download-as-csv-totals{
  margin: 0 28px 25px 0;
}

.user-confirmation-checkbox {
  margin-left: 16px;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;
  .modal-header-text {
    overflow-wrap: anywhere;
  }
}

.modal-button {
  margin: 8px;
}

.danger-button {
  background-color: #BA0916;
}

.totals-modal {
  overflow: visible;

  .data-row-dropdown {
    max-height: 60px;
  }
}

.edit-modal {
  position: absolute;
  width: 75%;
  height: 88%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overscroll-behavior: contain;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.modal-text-area {
  height: 425px;
  resize: vertical;
}

.modal-text-area-wide {
  height: 125px;
  width: 425px;
  resize: vertical;
}

.open-json-payload-modal, .center-button {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  .button {
    margin-bottom: unset;
  }
}

.open-json-payload-modal {
  .button.tertiary {
    margin: 8px;
  }
}

.store-service-add-row {
  margin-right: 8px;
}

.horizontal-control-block-element-left-actions-tab {
  justify-content: left;
  display: flex;
  margin-left: 8px;

  .fill-end-date {
    width: unset;
  }
}

.bulk-upload-stores-modal {
  width: 750px;
  min-height: 450px;

  h6 {
    margin-bottom: 32px;
  }

  input[type="radio"] {
    margin-right: 8px;
  }

  textarea {
    height: 125px;
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
    margin-bottom: 20px;
    resize: none;
    font-size: 12px;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  button[type="button"] {
    margin-left: 8px;
  }

  .validation-error-message {
    color: red;
    margin-top: 32px;
  }

  .ReactTable7 .rt-tbody .rt-td {
    font-size: 10px;
    line-height: 10px
  }

  .ReactTable7 .rt-tr {
    height: 30px;
  }

  .ReactTable7 .rt-thead .rt-resizable-header-content {
    font-size: 10px;
    line-height: 10px;
  }

  .ReactTable7 .-pagination {
    margin-top: 0;
    margin-bottom: 50px;
  }
}