.title-dropdown:focus {
  outline: none;
}

.title-dropdown-content {
  min-width: 25px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .header-button {
    cursor: pointer;
    color: #252525;
    padding: 8px 16px;
    width: 100%;
  }

  .header-button:hover {
    background-color: #F2F2F2;
  }

  .header-button:active {
    background-color: #E6F5FF;
  }

  button:focus {
    outline: none;
  }
}

.user-type-div {
  font-size: 12px;
  padding: 4px;
  background: darkgray;
  font-weight: bold;
  border-bottom: 2px solid black;
}