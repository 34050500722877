.breadcrumbs {
  li {
    color: var(--neutral-100, #252525);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body Small Heavy */
    //font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */

  }

  li::after {
    font-family: "Font Awesome 5 Free";
    font-size: 10px;
    height: 8px;
    width: 4px;
  }

  a {
    color: #666666;
  }

  a:hover {
    color: #666666;
    text-decoration: none;
    cursor: pointer;
  }
}

.breadcrumbs li:is(:last-child) {
  font-weight: bold;
  color: #252525
}

