.general-page-key-list {
  display: flex;
  flex-direction: column;
  padding: 4px;
  width: 260px;
}


.general-page-key {
  display: inline-block;

  .tooltip-contents {
    filter: drop-shadow(4px 4px 2px gray);
    z-index: 1000;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 244px;
  }

  .general-page-key-label {
    font-family: $body-font-family;
    display: inline-block;
    margin: 16px;
  }
}

.general-page-key-title {
  margin: 8px;
  text-align: center;
}

.general-key-status-color {
  display: flex;
  text-align: center;
}

.general-key-border {
  display: flex;
  border: 1px solid #B1B1B1;
  padding: 4px;
  height: 60px;
  width: 200px;
  align-items: center;
  justify-content: center;
}