$darkGreen: #225c04;
$darkRed: get-color(red-60);

.always-fill-color, .store-closure-color {
  color: $darkGreen;
}

.do-not-fill-color, .store-closed-color {
  color: $darkRed;
}

.store-date-start {
  border: 4px solid $darkGreen;
}

.store-date-stop {
  border: 4px solid $darkRed;
}

.always-fill {
  div {
    .fill-config-icon {
      @extend .always-fill-color;
    }
  }
}

.green {
  color: get-color(success)
}

.do-not-fill {
  div {
    .fill-config-icon {
      @extend .do-not-fill-color;
    }
  }
}


.red {
  color: get-color(alert)
}

.border-key {
  border-width: 2px;
  border-style: solid;
}

.tooltip-icon {
  position: relative;
}

.tooltip-contents {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #B1B1B1;
  z-index: 5;
  overflow: visible;
  margin-left: 16px;
  padding: 4px 8px;
}

.outlier-item-code-text {
  margin: 0 0 0 5px;
}

.archive-filename-close {
  float: right;
}