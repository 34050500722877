.page-info-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 8px 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #252525;
}


.retail-management-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .button {
    margin-left: 8px;
  }
}

.short-input {
  width: 500px;
}

#actions-tab {
  display: flex;
  flex-direction: column;

  button {
    margin-left: 8px;
  }
}

.retail-management-audit-section {
  margin-bottom: 20px;
  border-top: solid 1px #959595;

  .no-changes {
    padding-left: 10px;
    font-style: italic;
  }

  .config-change-record {
    padding-left: 10px;

    .updated-by {
      font-weight: bold;
    }

    .updated-at {
      font-style: italic;
    }
  }

  .config-update-item {
    padding-left: 10px;
    margin-bottom: 10px;
  }
}


.week-end-date-dropdown {
  display: flex;
  margin-right: 20px;
  align-items: center;

  .dropdown-label {
    min-height: 42px;
    padding: 0 1rem;
    border: 1px solid #cacaca;
    background: #e6e6e6;
    color: #0a0a0a;
    text-align: center;
    white-space: nowrap;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
  }
}

.composition-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin: 40px 0;
  border: solid 1px lightgray;
  border-radius: 10px;

  span {
    font-size: larger;
  }

  button {
    margin-top: 15px;
  }
}

.composition-table {
  h1 {
    margin-top: 60px;
  }

  .not-in-snapshot-container, .codeable-items-container {
    display: flex;
    justify-content: flex-start;
  }

  .download-csv-container {
    display: flex;
    justify-content: flex-end;

    .download-composition-report-csv {
      margin-left: 16px;
    }
  }
}

.retailer-info-bar {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #252525;
  background: #F4F6F8;
  border: 1px solid #E2E2E2;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 16px;

  .h5 {
    margin-bottom: 8px;
  }

  .retailer-info-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;

    .retailer-info-section {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;

      .retailer-name-value {
        display: flex;
        flex-direction: column;
        padding: 8px 0 20px 0;
        border-bottom: 1px solid #E2E2E2;
        width: 420px;
        margin-right: 50px;

        .label-name {
          font-weight: bold;
          margin-bottom: 4px;
        }
      }
    }

    .second-row {
      margin-bottom: unset;
      .retailer-name-value {
        border-bottom: unset;
        padding: 8px 0 0 0;
      }
    }
  }

  .salesforce-contact-info {
    display: flex;
    flex-flow: column;

  }

  .retail-management-buttons {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    .button {
      margin-left: 8px;
    }
  }
}
