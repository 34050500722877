ul.selectable-list {
  list-style-type: none;
  margin: 0;

  li {
    cursor: pointer;
    border-bottom: solid 1px #999;
    display: flex;
    justify-content: space-around;

    .selectable-list-item-label {
      flex: 1;
      padding: 0 20px;
    }

    .selectable-list-item-value {
      padding: 0 20px;
    }
  }

  li.selected {
    background-color: #7d9bb4;

  }
}
