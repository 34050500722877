.designation-header-component-container {
  display: inline-flex;
  object-fit: contain;
  align-items: center;

  button {
    margin-bottom: unset;
    background: var(--neutral-15, #E9E9E9);
    border-radius: 4px;

    i {
      color: #666666
    }
  }


  .designation-header-component-divider {
    height: 32px;
    margin: 0 20px 0 20px;
    width: 1px;
    background-color: #ccc;
  }

  .id-input {
    width: 300px;
    display: inline-flex;
    font-size: 14px;
    font-weight: unset;
    margin-right: 25px;
  }

}

.designation-action-buttons-container {
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;

  button, a {
    margin-bottom: unset;
    border-radius: 4px;

    i {
      color: #666666
    }
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .designation-header-component-divider {
    height: 24px;
    width: 1px;
    background-color: #ccc;
  }
}

.designation-action-button {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}


.multi-select-dropdown {
  width: 100%;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;

  div[class$="-control"] {
    border-color: #B1B1B1 !important;
    border-radius: 3px !important;
    border-style: solid;
  }

  input {
    height: 1px !important;
  }

  div[class$="select-dropdown__multi-value"] {
    background-color: #1271B2;
    color: #fefefe !important;

    div[class$="select-dropdown__multi-value__label"] {
      color: #fefefe !important;
    }
  }
}

.search-dropdown-container {
  width: 200px !important;

  .id-input {
    width: 200px;
    padding: 0 8px 0 0;
  }
}

.designation-assign-store-to-stores-selected-container {
  display: flex;

  .check-icon-true {
    color: #449711;
    line-height: 24px;
  }

  label {
    color: var(--neutral-80, #666);
    font-family: Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
  }

  .number-selected-rows {
    font-weight: 700;
    padding-left: 8px;
    padding-right: 4px;
  }
}

.line-break {
  display: block;
  margin-top: 20px;
}

.designation-bulk-assign-stores-modal {
  min-width: 325px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #FFF;
  box-shadow: 0 1px 3px 0 rgba(37, 37, 37, 0.15), 0 1px 0 0 rgba(37, 37, 37, 0.05);

  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  overflow: auto;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;

  h1 {
    color: var(--Neutral-100, #252525);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body Large */
    //font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    //align-items: flex-start;
    gap: 20px;
  }

  .button {
    width: 100px;
    color: #252525;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body Heavy */
    //font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */

    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #D9D9D9;
  }

  .selected-file-box {
    display: flex;
    height: 40px;
    max-width: fit-content;
    padding-left: 0;
    align-items: center;
    gap: 8px;
    border: 1px solid #D9D9D9;

    button {
      margin: 0;
      display: flex;
      width: 40px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border: 1px solid #D9D9D9;
      background: #F4F6F8;
    }
  }

  .bottom-button-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 65px;

    button {
      border-radius: 4px;
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 10px;
      margin-left: 15px;
    }
    button.close {
      background: #E9E9E9;
    }
    button.cancel {
      border: 1px solid #666;
      color: #666;
      background-color: #FFF;
    }
    button.primary {
      color: #FFF;
      background-color: #1271B2;
    }
  }

  textarea {
    margin: 10px 0;
  }

  a {
    color: #1271B2;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body */
    //font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.designation-settings {
  .settings-header {
    margin-top: 16px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .checkbox-label {
    margin-top: 0;
  }

  .checkbox {
    margin-left: auto;
    height: 16px;
    width: 16px;
    align-self: center;
  }
}

.designation-copy-button {
  margin-right: 8px;
}