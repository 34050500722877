.total-dollar-store-name {
  text-align: left;
  white-space: nowrap;
  background: #f4f6f8;
}

.total-dollar-store-id {
  background: #f4f6f8;
}

.total-dollar-average {
  background: #f4f6f8;
}

.advanced-options-row {
  .select-dropdown {
    z-index: 10;
  }
}

.total-dollars-key {
  display: inline-block;
  height: 50px;
  padding-top: 8px;
}

.total-dollars-key-list {
  display: flex;
  flex-direction: column;
  padding: 4px;
  width: 260px;

  .name-value-label :first-child {
    padding-right: 4px;
    padding-left: 4px;
  }

  .name-value-label {
    padding: 4px 0;
    justify-content: space-between;
    height: 24px;
  }

}
