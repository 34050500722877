.project-factors-page-controls {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .left-things {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 12px;
    margin-bottom: 16px;

  }
}