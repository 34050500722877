
.title-object {
  margin-left: auto;
}


.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  &> div:first-child {
    display: flex;
    height: 32px;
    align-items: center;
    gap: 20px;
  }

  .page-header-label {
    color: var(--neutral-100, #252525);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 1 */
    //font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 350;
    line-height: 32px; /* 133.333% */
  }

  .page-header-secondary-label {
    color: var(--neutral-80, #666);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body */
    //font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.page-top-buttons {
  display: flex;
  margin: 20px;

  button {
    margin: 0;
  }
}