.collapsable-content-container {
  display: flex;
}

.collapsable-content {
  margin-left: 8px;

  ul {
    margin-left: 0;
  }
}

.task-chevron {
  width: 12px;
  height: 12px;
  margin: 4px 0 0 4px;
}

.task-chevron-open {
  width: 12px;
  height: 12px;
  margin: 4px 0 0 4px;
  transform: rotate(90deg)
}
