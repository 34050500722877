.right-exit-button {
  display: flex;
  justify-content: flex-end;
  margin-left: 202px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 12px;
  border-bottom-style: none;
  outline: none;
  color: #474747;
}

.right-things {
  display: flex;
  justify-content: flex-end;
  padding: 17px 0 7px 0;

}

.confirmation-modal {
  height: fit-content;
}
