.readiness-message {
  font-family: $body-font-family;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 40px;
  padding: 20px;
}

.readiness-error-message {
  background-color: $alert-color;
  color: black;
}

.readiness-info-message {
  background-color: #d6ebff;
  color: #1b508f;
  border: 4px solid $primary-color;
}