.key-account-container {
  .key-account-header {
    font-weight: bold;
    text-align: center;
    padding: 0 0 16px 0;
  }

  .key-account-no-data-found {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: bold;
    text-align: center;
    padding: 16px 0 16px 0;
    background-color: #e6e6e6;
  }

  .key-account-retailer-contents, .non-key-account-retailer-contents, .non-market-account-retailer-contents {
    width: 100%;
    padding: 24px 24px 0 24px;
  }

}

.readiness-content {
  margin-top: 25px;
}

.readiness-options {
  display: flex;

  #check-boxes {
    margin-left: 48px;
  }
}

.readiness-issues {
  font-size: 14px;
}

.outliers-readiness-column {
  display: flex;
}

.dollars-count-alert-link, .units-count-alert-link, .records-count-alert-link {
  margin: 0 8px 0 8px;
  color: #e32836;
}

.missing-dates-list {
  margin-top: 8px;

  .missing-dates-days-list {
    list-style: none;

    li {
      padding-left: 1em;
      text-indent: -1em;
    }

    li:before {
      content: '\2715';
      padding-right: 5px;
      color: #BA0916;
      font-weight: bold;
    }

  }
}
