.task.success {
  background: unset;
  text-align: start;

  a {
    color: get-color(green-60);
  }
}

.task.alert {
  background: unset;
  text-align: start;

  a {
    color: get-color(red-60);
  }
}

.horizontal-control-block-space-around {
  display: flex;
  justify-content: space-around;
}

.previous-run-summary-icon-success {
  color: get-color(green-60);
}

.previous-run-summary-icon-failure {
  color: get-color(red-60);
}

.success-image, .alert-image {
  background: unset;
}

.number-field {
  text-align: center;
}

#switches {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  .switch {
    margin-left: 8px;
    margin-bottom: 0;

    .Dropdown-control {
      margin-left: 8px;
    }
  }

  .tooltip-contents {
    margin-top: 8px;
  }

  .general-page-key .general-page-key-label {
    margin: 0 0 0 8px;
  }
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: center;

  .dropdown-for-days {
    width: 100px;
    padding: 0 8px 0 4px;
  }

  .checkbox-checkboxes {
    display: flex;
  }


  .checkbox-header {
    font-family: sans-serif;
  }
}

.ingestion-logs-divider {
  height: 60px;
  margin-bottom: 0;
  width: 1px;
  background-color: #ccc;
}

.ingestion-logs-no-line-divider {
  height: 60px;
  margin-bottom: 0;
  width: 1px;
}

#check-boxes, #input-group {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .checkbox-container {
    display: flex;
    align-items: center;
    font-size: 12px;
    text-align: left;
    font-family: sans-serif;

    .Dropdown-control {
      margin-left: 8px;
      margin-right: 16px;
    }

    .checkbox-filters-label {
      left: 20px;
      top: 22px;
      font-size: 14px;
      line-height: 28px;
    }

    .checkbox-label {
      background-color: #E9E9E9;
      border: 1px solid #ccc;
      border-radius: 20px;
      cursor: pointer;
      height: 32px;
      text-align-last: center;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 2px;
      margin: 0 0.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .is-data-issue-checkbox-label {
      color: black;
      background-color: #FDC2C2;
      border: 1px solid #FDC2C2;
    }

    .is-not-data-issue-checkbox-label {
      color: black;
      background-color: #E2E2E2;
      border: 1px solid #E2E2E2;
    }

    .is-further-review-checkbox-label {
      color: black;
      background-color: #F9EABF;
      border: 1px solid #F9EABF;
    }


    .checkbox-label:before {
      content: '✓';
      line-height: 1em;
      width: 1em;
      height: 1em;
      text-align: center;
      opacity: 0;
      display: none;
    }

    input[type="checkbox"] {
      visibility: hidden;
      margin-bottom: unset;
      margin-left: 10px;
      display: none;
    }

    input[type="checkbox"]:checked + .checkbox-label {
      background-color: #666666;
      border-color: #666666;
      color: #FFFFFF;
      font-weight: bold;
    }

    input[type="checkbox"]:checked + .is-data-issue-checkbox-label {
      color: black;
      background-color: #FA7978;
      border: 1px solid #FA7978;
      font-weight: bold;
    }

    input[type="checkbox"]:checked + .is-not-data-issue-checkbox-label {
      color: black;
      background-color: #C7C7C7;
      border: 1px solid #C7C7C7;
      font-weight: bold;
    }

    input[type="checkbox"]:checked + .is-further-review-checkbox-label {
      color: black;
      background-color: #FADB7A;
      border: 1px solid #FADB7A;
      font-weight: bold;
    }


    input[type="checkbox"]:checked + .checkbox-label:before {
      opacity: 1;
      display: inline-block;
    }

  }

  .empty-div-40-percent {
    width: 40%;
  }

  input[type="text"] {
    flex: 0 0 10%;
  }
}

.mapping {
  text-transform: capitalize;
}

.ingest-cards {
  display: flex;
  flex-direction: row;
  background-color: #D9D9D9;
  padding: 8px;
  margin-bottom: 16px;

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 50%;
    overflow: visible !important;

    .sub-cards {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;

      .card-content {
        margin: 8px 0;
        border: solid 1px lightgrey;
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .retailer-data {
          font-size: 24px;
          font-weight: bold;
        }

        .content-label {
          font-size: 12px;
        }
      }
    }
  }

}

.tab-radio-buttons {

  display: flex;
  flex-direction: row;

  .tab-radio-button-container {
    display: flex;
    align-items: center;
    font-size: 12px;
    text-align: center;
    font-family: sans-serif;

    input.tab-radio-button {
      display: none;
    }

    input.tab-radio-button + label {
      cursor: pointer;
      padding: .5em 1em;
      left: 20px;
      top: 22px;
      font-size: 14px;
      line-height: 28px;
      text-transform: capitalize;
      border-bottom: 2px solid #FFFFFF;
    }

    input.tab-radio-button + label:hover {
      border-bottom: 2px solid #666666;
    }

    [type=radio]:checked + label {
      border-bottom: 2px solid #808080;
      font-weight: bold;
    }
  }

  .tab-download-csv {
    display: flex;
    align-self: flex-end;
    margin-left: auto;
  }
}

.download-csv {
  vertical-align: top;
  align-self: flex-end;
  margin-left: 5px;
  float: right;
  border-radius: 4px;
}

.left-margin {
  margin: 0 0 0 12px
}

.collapsable-content {
  .hover-tool-tip-div {
    .tooltip-contents {
      position: fixed;
    }
  }
}

.hover-tool-tip-div {
  float: right;

  .hover-tool-tip-div-hover {
    display: flex;
    flex-direction: column;
    padding: 4px;
    width: 320px;

    .name-value-label :first-child {
      padding-right: 4px;
      padding-left: 4px;
    }

    .name-value-label {
      justify-content: space-between;
    }

  }
}

.bottom-border {
  border-bottom: black;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.feedback {
  display: flex;
  flex-direction: row;
}

.width-80 {
  width: 80px;
}

.show-onboarding {
  background-color: get-color(light-blue)
}

.onboarding-label {
  background-color: get-color(light-blue);
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  padding: 4px;
  margin-left: 4px;
}

#confluence-link {
  overflow-wrap: break-word;
}

.ingestion-logs-page-key-list {
  display: flex;
  flex-direction: column;
  padding: 4px;
  width: 260px;
}


.ingestion-logs-page-key {
  display: inline-block;

  .tooltip-contents {
    filter: drop-shadow(4px 4px 2px gray);
    z-index: 1000;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 244px;
  }

  .ingestion-logs-page-key-label {
    font-family: $body-font-family;
    display: inline-block;
    margin: 16px;
  }
}

.ingestion-logs-page-key-title {
  margin: 8px;
  text-align: center;
}

.ingestion-status-color {
  display: flex;
  text-align: center;
}

.ingestion-logs-key-border {
  display: flex;
  border: 1px solid #B1B1B1;
  padding: 4px;
  height: 60px;
  width: 200px;
  align-items: center;
  justify-content: center;
}

.manifest-container {

  .manifest-table-dropdown-container {
    width: 100%;

    div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
      min-height: 40px !important;
      max-height: 40px;
    }

    div[class$="-Input"], div[class$="manifest-table-dropdown__input"] {
      min-height: 40px !important;
      max-height: 40px;
      padding: 0;
      input {
        box-sizing: border-box !important;
        border-radius: 3px !important;
        height: 24px !important;
        padding: 0 !important;
        background: 0 center !important;
        border: 0 !important;
      }
    }

    div[class$="-control"] {
      min-height: 40px !important;
      max-height: 40px;
    }

    div[class$="-ValueContainer"], div[class$="_value-container"], .manifest-table-dropdown__value-container {
      height: 40px !important;
      overflow: unset;
    }
  }
}

.dropdown-for-days-component-container {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: left;
  font-family: sans-serif;

  .dropdown-for-days__value-container {
    min-height: 32px !important;
    max-height: 32px;
  }

  div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
    min-height: 32px !important;
    max-height: 32px;
  }

  div[class$="-Input"] {
    min-height: 32px !important;
    max-height: 32px;
    padding: 0;
  }

  div[class$="-control"] {
    min-height: 32px !important;
    max-height: 32px;
  }

  .modal-ftp-configuration-dropdown__menu {
    z-index: 10 !important;
  }
}
.modal-ftp-configuration-dropdown {
  width: 100%;
  padding-bottom: 4px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;

  .select-dropdown__value-container {
    min-height: 40px !important;
    max-height: 40px;
  }

  div[class$="IndicatorsContainer"], div[class$="-indicatorContainer"] {
    min-height: 40px !important;
    max-height: 40px;
  }

  div[class$="-Input"] {
    min-height: 40px !important;
    max-height: 40px;
    padding: 0;
  }

  div[class$="-control"] {
    min-height: 40px !important;
    max-height: 40px;
  }

  .modal-ftp-configuration-dropdown__menu {
    z-index: 10 !important;
  }
}




