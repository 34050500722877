
@function column-width($columns) {
  @return percentage(1/$columns);
}

.edit-modal-data-row {
  .data-table-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.data-table {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;

  .odd-row {
    background: #FAFAFA;
  }

  .last-period {
    background: #D4D4D4;
  }

  .invalid-time-service-week {
    font-weight: bold;
    color: red;
  }

  .data-table-row {
    display: flex;
    flex-direction: row;

    .data-cell:not(:last-child) {
      border-right: 2px solid #E9E9E9;
    }

    .data-cell {
      overflow: visible;
      padding: 10px 12px;

      .createable-select {
        width: 100%;
      }
    }

    .duplicated {
      background: get-color(alert);
    }
  }

  .data-table-header-row {
    background: #F4F6F8;
    font-weight: bold;

    .data-table-row {
      border-bottom: 1px solid #E2E2E2;
      height: 100%;

      .data-cell{
        padding: 0 12px;
        display: flex;
        align-items: center;
        min-height: 48px;
        height: auto;
      }
    }

    &.sticky {
      position: fixed;
      top: 40px;
      margin: unset;
    }
  }

  .data-table-search-row {
    .data-table-row {
      border-bottom: 1px #E2E2E2;
      height: 100%;

      .data-cell{
        height: 48px;
        padding: 8px;
      }
      .editable-cell {
        background: #F2F2F2;
        input {
          height: 32px;
        }
      }
    }

    &.sticky {
      position: fixed;
      top: 80px;
      margin: unset;
    }
  }


  .data-table-pagination {
    width: 100%;
    background: #fff;
    margin: 0 auto;
    // position: fixed;  enabling this fixes the pagination at the bottom of the page (good) but (for market service at least) causes pagination to hide data rows
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }

  .button {
    margin: 15px;
  }
}

.data-row-cell-select__value-container {
  height: 2.5rem;
}


.data-row-cell-select__menu {
  z-index: 1000;
  overflow: visible;
}

.action-cell-buttons {
  display: flex;
  justify-content: center;

  button {
    margin: 0;
  }

  a, i {
    color: #495057;
    padding: 8px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: lightgray;
      color: #1779ba;
    }
  }
}

.short-rows {
  .data-table {
    .data-table-row {
      .data-cell {
        height: auto;
      }
    }
  }
}

.totals-header-emphasis {
  color: get-color(red-60);
}

.table-dropdown {
  overflow: visible !important;
}

.manifest-container {
  display: flex;
  height: 58px;
  button {
    margin-left: 8px;
  }
  .table-dropdown {
    width: 150px;
  }
}

.super-small {
  margin: 5px;
  border: 1px solid black;
  padding: .2rem;
  font-size: .8rem;
}

.action-buttons {
  display: inline-block;
  white-space: nowrap;

}