.what-will-fill-selection-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;

  .column {
    display: flex;
    flex-direction: column;
    .date-label{
      height: 24px;
    }
  }

  .selections-divider {
    height: 80px;
    width: 1px;
    background-color: #ccc;
  }

  .left-margin {
    margin: 0 0 0 12px
  }
}

.invalid-day-of-week{
  font-weight: bold;
  background: #e6b800;
  margin: -12px;
  padding: 20px 12px;
}
