.header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header-session-user {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  padding: 0 8px 0 0;
  color: white;
}

.nav-bar {
  border-bottom: 1px solid #D9D9D9;
}

.spins-logo {
  width: 88px;
  height: 20px;
  border-right: #F2F2F2;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 8px;
}

.dropdown-menu-link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-menu-link-small {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #252525 !important;
  //background: #fefefe;
  padding: 0.7rem 1rem;

  &:hover {
    background-color: #F2F2F2;
  }
}

.dropdown-menu-link-large {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
}


.dashboard-title {
  width: 87px;
  height: 22px;
  font-style: normal;
  font-weight: 300;
  color: white;
  font-size: 18px;
  line-height: 22px;
  margin-left: 8px;
}

.title-bar-container {
  top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  order: 0;
  margin: 4px 16px;
  width: 100%;
  height: 40px;
}

.nav {
  padding: 0 8px;
  width: 100%;
  display: flex;

  .i {
    float: left;
    cursor: pointer;
    color: #FFF;
    display: flex;
    text-decoration: none;
    font-size: 12px;
    margin-left: 80px;
    padding: 15px;
  }

  .link {
    color: #FFFFFF !important;
  }

  .link:hover {
    color: #000000 !important;
  }
}

.z-to-the-top, .react-datepicker-popper {
  z-index: 100;
}

.header-session-timeout {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: white;
}

.header-next-period {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: white;
  padding-right: 25px;
}

.separator {
  border-top: solid 1px rgba(0, 0, 0, 0.25);
}