.checkpoint-spotlight-page-key-list {
  display: flex;
  flex-direction: column;
  padding: 4px;
  width: 300px;
}

.spotlight-info-header {
  flex-direction: row;

  .view-spotlights-container {
    flex-direction: column;
    display: flex;
    align-self: flex-end;

    .view-spotlights-button-container {
      display: flex;
      justify-content: flex-end;
    }
  }

  .selected-spotlight-container {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
  }
}


.checkpoint-spotlight-page-key {
  display: inline-block;

  .tooltip-contents {
    filter: drop-shadow(4px 4px 2px gray);
    z-index: 1000;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 275px;
  }

  .checkpoint-spotlight-page-key-label {
    font-family: $body-font-family;
    display: inline-block;
    margin: 16px;
  }
}

.checkpoint-spotlight-page-key-title {
  margin: 8px;
  text-align: center;
}
