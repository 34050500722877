.react-contextmenu-wrapper {
  cursor: pointer;
  height: 100%;
}

.react-contextmenu {
  background-color: lightgrey;
  border: 2px solid;
  z-index: 100;

  div {
    cursor: pointer;
    padding: 4px 12px;
  }

  div + div {
    border-top: 2px solid;
  }
}
